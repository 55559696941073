import React, { useEffect } from 'react'


const Payment = ({ encryptedData }) => {
    useEffect(() => {

        document.getElementById('redirectForm').submit();
    }, []);


    return (
        <form id="redirectForm" method="post" name="redirect" action="https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction">
            <input type="hidden" id="encRequest" name="encRequest" value={encryptedData} />
            <input type="hidden" name="access_code" id="access_code" value="AVWY76LD60AH51YWHA" />
            <input type="hidden" name="merchant_id" id="merchant_id" value="2837454" />
        </form>
    );
}

export default Payment