import * as React from 'react';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Rooms from './Rooms';
import Header from './header';
import RoomBooking from './roombooking';
import CompleteBooking from './CompleteBooking';
import AllDetails from './AllDetails';
import Payments from './Payments';
import PaymentSuccess from './PaymentSuccess';
import PaymentFailed from './PaymentFailed';
{/* <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap"/> */ }


const App = () => {
  return (

    <BrowserRouter>
      <div>
        <Header />
      </div>
      <Routes>
        <Route path="/" element={<Rooms />} />
        <Route path="/room-booking/:id/:type" element={<RoomBooking />} />
        <Route path="/complete-booking" element={<CompleteBooking />} />
        <Route path="/all-details/:id" element={<AllDetails />} />
        <Route path="/payments" element={<Payments />} />
        <Route path="/payment-success" element={<PaymentSuccess/>}/>
        <Route path="/payment-failed" element={<PaymentFailed/>}/>
      </Routes>
    </BrowserRouter>

  );
};

export default App;
