// eslint-disable-next-line no-undef
import * as React from 'react';
import { Grid } from '@mui/material';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useState } from 'react';
import CompleteBooking from './CompleteBooking';
import { ToastContainer } from 'react-toastify';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import './App.css';
import { enqueueSnackbar } from 'notistack'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function RoomBooking() {
    const { id, type } = useParams();
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [quantity, setQuantity] = useState(0);
    const [displayBooking, setDisplayBooking] = useState(false);

    const opensuccesstoast = (message) => {
        enqueueSnackbar(message, {
            variant: 'success',
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            autoHideDuration: 2000
        });
    };

    const openfailuretoast = (message) => {
        enqueueSnackbar(message, {
            variant: 'default',
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            autoHideDuration: 2000
        });
    };



    const schema = yup.object().shape({
        startDate: yup.date().required('Start Date is required'),
        endDate: yup.date().required('End Date is required'),
        quantity: yup.number()
            .min(1,)
            .positive('Quantity must be a positive number')
            .integer()
            .required('Quantity is required'),
    });

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = async (data) => {
        try {
            const response = await axios.post("https://phpstack-977481-4439950.cloudwaysapps.com/checkRoomsAvailability", {
                roomid: Number(id),
                startdate: data.startDate,
                enddate: data.endDate,
                quantity: Number(data.quantity)
            });

            if (response.data.status) {
                setDisplayBooking(true);
                toast.success('Room Available', { autoClose: 2000 })
            } else {
                toast.error('Rooms are Unavailable', { autoClose: 2000 });
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const handleClick = async () => {
        try {
            console.log("start date..", startDate);
            console.log("end date..", endDate);
            const response = await axios.post("https://phpstack-977481-4439950.cloudwaysapps.com/checkRoomsAvailability", {
                roomid: Number(id),
                startdate: startDate,
                enddate: endDate,
                quantity: Number(quantity)
            });

            console.log("response...", response.data);

            if (response.data.status === true) {
                opensuccesstoast('Room are Available')
                setDisplayBooking(true);
            } else {
                openfailuretoast('Rooms are Unavailable')

            }
        } catch (error) {
            console.log("error", error);
        }
    };

    const handleButtonClick = () => {
        handleClick();
        handleSubmit(onSubmit);
    };

    console.log("paramsss", id, type);

    return (
        <>

            <Grid container className="mainGrid" spacing={5} sx={{ alignItems: 'center', justifyContent: 'center', minHeight: '100vh' }}>
                <Grid item md={9} xs={12} sm={12} className="roomcal" sx={{ alignItems: 'center', justifyContent: 'center' }}>
                    <Box component="section" className='roomBox' sx={{}}>
                        <Typography variant="h5" component="div" sx={{ textAlign: 'center', margin: '20px' }} fontWeight="bold">
                            Book a Room
                        </Typography>


                        <form onSubmit={handleSubmit(onSubmit)}>
                            {/* <button onClick={() => functions()}>click</button> */}
                            <Stack spacing={{ xs: 1, sm: 2, md: 6, lg: 6 }} direction="row" useFlexGap flexWrap="wrap" justifyContent={'center'}>
                                <div>
                                    <Typography>Check-in</Typography>
                                    <TextField id="startDate" {...register('startDate')} variant="outlined" value={startDate} onChange={(e) => setStartDate(e.target.value)} size="large" type='date' sx={{ width: '270px' }} error={!!errors.startDate} helperText={errors.startDate?.message} inputProps={{ min: new Date().toISOString().split('T')[0] }} />

                                </div>
                                <div>
                                    <Typography>Check-out</Typography>
                                    <TextField id="endDate" {...register('endDate')} variant="outlined" value={endDate} size="large" onChange={(e) => setEndDate(e.target.value)} type='date' sx={{ width: '270px' }} error={!!errors.endDate} helperText={errors.endDate?.message} inputProps={{ min: new Date().toISOString().split('T')[0] }} />
                                </div>
                            </Stack>

                            <Stack spacing={{ xs: 1, sm: 2, md: 6, lg: 6 }} direction="row" useFlexGap flexWrap="wrap" justifyContent={'center'}>
                                <TextField id="type" {...register('type')} label="Room Type" variant="outlined" className='roomType' value={type || ''} size="large" type='text' sx={{ width: '270px' }} />
                                <TextField id="quantity" {...register('quantity')} label="Number of Rooms" variant="outlined" className='roomType' value={quantity || ''} onChange={(e) => setQuantity(e.target.value)} size="large" type='number' sx={{ width: '270px' }} error={!!errors.quantity} helperText={errors.quantity?.message} />
                            </Stack>
                            <Grid container justifyContent="center">
                                <Grid item sx={{ margin: '30px' }}>
                                    <Button variant="contained" type="submit" className='bookbtn' onClick={handleButtonClick}>
                                        Check Availability
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>

                        <ToastContainer />
                        {displayBooking ? <CompleteBooking roomid={id} quantity={quantity} startDate={startDate} endDate={endDate} /> : ''}
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}
