import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Button } from '@mui/material';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './App.css';
import { BASEURL } from './config';



export default function Rooms() {
    const [getRooms, setGetRooms] = useState([]);

    useEffect(() => {
        GetAllRooms();
    }, [])
    const GetAllRooms = async () => {
        const response = await axios.post(`${BASEURL}/getAllRooms`)
        try {
            if (response.data.status === true) {
                setGetRooms(response.data.response)

            }
            else {

                console.log('status false')
            }
        }
        catch (error) {
            console.log('get rooms api status', error)
        }
    }
    console.log('getRooms', getRooms)


    return (
        <>

            <Box sx={{ flexGrow: 1, margin: "20px" }}>
                <Grid container className="mainGrid" spacing={5}>
                    {getRooms.map((item, index) => (
                        <Grid item md={5} xs={12} sm={12} className="grids" key={index}>
                            <Card sx={{ maxWidth: "100%" }} className='RoomsCard'>
                                <CardMedia
                                    sx={{ height: 350 }}
                                    image={item.imageUrl}
                                    title="Rooms"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        {item.roomtype}
                                    </Typography>
                                    <Typography variant="h6" >
                                        Rs.{item.rate} / Day
                                    </Typography>
                                    <Typography variant="subtitle1" color="text.dark" sx={{fontSize:'14px'}}>
                                        (Including GST)
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button component={Link} to={{
                                        pathname: `/room-booking/${item.roomid}/${item.roomtype}`,
                                        state: { value: 1 }, // Pass the roomData object as state
                                    }} variant="contained" className='bookbtn'>
                                        Book Now
                                    </Button>
                                </CardActions>
                                <Typography variant="p" sx={{color:'red', fontSize:'13px'}}>*Extra persons and bed charges are applicable</Typography>
                            </Card>
                        </Grid>
                    ))}

                </Grid>
            </Box>
        </>
    );
}