import React from 'react'
import { Button, Typography } from '@mui/material'
import { Modal } from '@mui/material'
import { Box } from '@mui/material'

const PaymentSuccess = () => {

    const rootRef = React.useRef(null);
    return (
        <Box
            sx={{
                height: '100vh',
                flexGrow: 1,
            }}
            ref={rootRef}
        >
            <Modal
                disablePortal
                disableEnforceFocus
                disableAutoFocus
                open
                aria-labelledby="server-modal-title"
                aria-describedby="server-modal-description"
                sx={{
                    display: 'flex',
                    p: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
                container={() => rootRef.current}
            >
                <Box
                    sx={{
                        position: 'relative',
                        width: 400,
                        bgcolor: '#fff',
                        border: '2px solid #fff',
                        borderRadius: 1,
                        boxShadow: (theme) => theme.shadows[5],
                        p: 4,
                    }}
                >
                    <Typography id="server-modal-title" variant="h5" component="h2">
                        Payment Success
                    </Typography>
                    <Typography id="server-modal-description" sx={{ pt: 2 }}>
                        Your Payment is successfully proceeded
                    </Typography>
                    <a href="/">
                        <Button sx={{
                            color: '#438f46',
                            pt: 2,
                            marginTop: 2
                        }}>
                            Okay
                        </Button>
                    </a>
                </Box>
            </Modal>
        </Box>
    )
}

export default PaymentSuccess