import React from 'react';
import AppBar from '@mui/material/AppBar';
import { Box, Toolbar} from '@mui/material';
import logo from './Sunshine logo.png';
import Button from '@mui/material/Button';

const Header = () => {
    return (
        <Box>
            <AppBar sx={{ background: "#fff" }} position="static">
                <Toolbar sx={{ display: "flex", justifyContent: "space-between", height: 80 }}>
                    <Box> 
                        {/* component="img" href='https://sunshinehotel.co.in'
                        sx={{ height: 60 }}
                        alt="Logo"
                        src={logo} */}

                        <a href="https://sunshinehotel.co.in">
                        <img src={logo} alt="logo Image" width={'220px'} className='logo-header'/>
                    </a>
                    </Box>  
                    
                    <Button variant="contained" className='bookbtn' href='https://sunshinehotel.co.in'>
                        Back to Home
                    </Button>
                </Toolbar>
            </AppBar>
        </Box>
    )
}

export default Header;