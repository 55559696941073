//eslint-disable-next-line
import React from "react";
import { Grid, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Modal } from '@mui/material'
import { Box } from '@mui/material'
import { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import './App.css';
import Payment from './Payments';

export default function AllDetails() {
    const { id } = useParams();
    const [paymentComponent, setPaymentComponent] = useState(false)
    const [encryptedData, setEncryptedData] = useState('')
    const { orderheaderid } = useParams();
    const [orderDetails, setOrderDetails] = useState(null)
    const [openModal, setOpenModal] = useState(false)

    useEffect(() => {

        const getOrderDetails = async () => {
            try {

                const response = await axios.post('https://phpstack-977481-4439950.cloudwaysapps.com/getBookingById', {
                    orderheaderid: Number(id)
                });

                if (response.data.status === true) {

                    setOrderDetails(response.data.response)
                }
                else {

                    console.log('status false')
                }
            }
            catch (error) {
                console.log('get rooms api status', error)
            }
        }
        getOrderDetails();
    }, [id])

    // const handleClick = async () => {
    //     const response = await axios.post('https://phpstack-977481-4439950.cloudwaysapps.com/createCheckoutSession',
    //         {
    //             "merchant_id": "2837454",
    //             "order_id": orderDetails.orderheaderid,
    //             "currency": "INR",
    //             "amount": orderDetails.totalAmount,
    //             "redirect_url": "https://phpstack-977481-4439950.cloudwaysapps.com/paymentVerification",
    //             "cancel_url": "https://booking.sunshinehotel.co.in/payment-failed",
    //             "language": "EN",
    //             "billing_name": orderDetails.customerName,
    //             "billing_address": orderDetails.address,
    //             "billing_city": orderDetails.city,
    //             "billing_state": orderDetails.state,
    //             "billing_zip": orderDetails.pincode,
    //             "billing_country": "India",
    //             "billing_tel": orderDetails.phoneno,
    //             "billing_email": orderDetails?.email,
    //             "delivery_name": orderDetails.customerName,
    //             "delivery_address": orderDetails.address,
    //             "delivery_city": orderDetails.city,
    //             "delivery_state": orderDetails.state,
    //             "delivery_zip": orderDetails.pincode,
    //             "delivery_country": "India",
    //             "delivery_tel": orderDetails.phoneno,
    //             "merchant_param1": "",
    //             "promo_code": "",
    //             "customer_identifier": ""
    //         }
    //     );

    //     if (response.data.status) {
    //         setEncryptedData(response.data.response)
    //         setPaymentComponent(true)
    //     }

    // }
    console.log("# orderheaderid, order details...", orderheaderid, orderDetails)


    const handleButtonClick = () => {
        setOpenModal(true);
    }

    const handleCloseModal = () => {
        setOpenModal(false);
    }

    return (
        <>

            <Grid container spacing={2} sx={{ alignItems: 'center', justifyContent: 'center', minHeight: '100vh', padding: '10px' }}>

                <Grid item xs={12} sm={12} md={12} sx={{ textAlign: 'center' }}>
                    <Typography variant="h6" fontWeight="bold">FEW MORE STEPS TO COMPLETE</Typography>
                </Grid>

                <Grid item xs={10} sm={10} md={7} style={{ backgroundColor: '#f0f0f0', padding: '25px', margin: '10px' }}>

                    <div style={{ textAlign: 'left' }}>
                        <Typography variant="h6" fontWeight={'Bold'}>Customer Details</Typography>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                        <Typography variant="body2" sx={{ fontWeight: 600 }}>Name : </Typography>
                        {/* <TextField id="standard-basic"
                            value={orderDetails?.customerName}
                            variant="standard" sx={{ width: '80%', marginLeft: '10px' }}
                            InputProps={{
                                style: { fontSize: '14px' }
                            }}
                        /> */}
                        <Typography variant="body2" sx={{ marginLeft: '10px' }}>{orderDetails?.customerName}</Typography>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                        <Typography variant="body2" sx={{ fontWeight: 600 }}>E-mail :  </Typography>
                        {/* <TextField value={orderDetails?.email}
                            id="standard-basic" variant="standard" sx={{ width: '80%', marginLeft: '10px' }}
                            InputProps={{
                                style: { fontSize: '14px' }
                            }} /> */}
                        <Typography variant="body2" sx={{ marginLeft: '10px' }}>{orderDetails?.email}</Typography>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                        <Typography variant="body2" sx={{ fontWeight: 600 }}>Phone.no :  </Typography>
                        {/* <TextField value={orderDetails?.phoneno}
                            id="standard-basic" variant="standard" sx={{ width: '80%', marginLeft: '10px' }}
                            InputProps={{
                                style: { fontSize: '14px' }
                            }} /> */}
                        <Typography variant="body2" sx={{ marginLeft: '10px' }}>{orderDetails?.phoneno}</Typography>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                        <Typography variant="body2" sx={{ fontWeight: 600 }}>Address :  </Typography>
                        {/* <TextField value={orderDetails?.address}
                            id="standard-basic" variant="standard" sx={{ width: '80%', marginLeft: '10px' }}
                            InputProps={{
                                style: { fontSize: '14px' }
                            }} /> */}
                        <Typography variant="body2" sx={{ marginLeft: '10px' }}>{orderDetails?.address}</Typography>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                        <Typography variant="body2" sx={{ fontWeight: 600 }}>City :  </Typography>
                        {/* <TextField value={orderDetails?.city}
                            id="standard-basic" variant="standard" sx={{ width: '80%', marginLeft: '10px' }}
                            InputProps={{
                                style: { fontSize: '14px' }
                            }} /> */}
                        <Typography variant="body2" sx={{ marginLeft: '10px' }}>{orderDetails?.city}</Typography>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                        <Typography variant="body2" sx={{ fontWeight: 600 }}>State :  </Typography>
                        {/* <TextField value={orderDetails?.state}
                            id="standard-basic" variant="standard" sx={{ width: '80%', marginLeft: '10px' }}
                            InputProps={{
                                style: { fontSize: '14px' }
                            }} /> */}
                        <Typography variant="body2" sx={{ marginLeft: '10px' }}>{orderDetails?.state}</Typography>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                        <Typography variant="body2" sx={{ fontWeight: 600 }}>Pincode :  </Typography>
                        {/* <TextField value={orderDetails?.pincode}
                            id="standard-basic" variant="standard" sx={{ width: '80%', marginLeft: '10px' }}
                            InputProps={{
                                style: { fontSize: '14px' }
                            }} /> */}
                        <Typography variant="body2" sx={{ marginLeft: '10px' }}>{orderDetails?.pincode}</Typography>
                    </div>


                    <div style={{ textAlign: 'left', marginTop: '30px' }}>
                        <Typography
                            variant="h6" fontWeight={'Bold'}>Booking Details</Typography>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                        <Typography variant="body2" sx={{ fontWeight: 600 }}>Arriving Date :  </Typography>
                        {/* <TextField value={orderDetails?.startdate}
                            id="standard-basic" variant="standard" sx={{ width: '80%', marginLeft: '10px' }}
                            InputProps={{
                                style: { fontSize: '14px' }
                            }} /> */}
                        <Typography variant="body2" sx={{ marginLeft: '10px' }}>{orderDetails?.startdate}</Typography>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                        <Typography variant="body2" sx={{ fontWeight: 600 }}>Departure Date :  </Typography>
                        {/* <TextField value={orderDetails?.enddate}
                            id="standard-basic" variant="standard" sx={{ width: '80%', marginLeft: '10px' }}
                            InputProps={{
                                style: { fontSize: '14px' }
                            }} /> */}
                        <Typography variant="body2" sx={{ marginLeft: '10px' }}>{orderDetails?.enddate}</Typography>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                        <Typography variant="body2" sx={{ fontWeight: 600 }}>Room Type :  </Typography>
                        {/* <TextField value={orderDetails?.rooms.roomtype}
                            id="standard-basic" variant="standard" sx={{ width: '80%', marginLeft: '10px' }}
                            InputProps={{
                                style: { fontSize: '14px' }
                            }} /> */}
                        <Typography variant="body2" sx={{ marginLeft: '10px' }}>{orderDetails?.rooms.roomtype}</Typography>
                    </div>
                    
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                        <Typography variant="body2" sx={{ fontWeight: 600 }}>Booking Id :  </Typography>
                        {/* <TextField value={orderDetails?.rooms.roomtype}
                            id="standard-basic" variant="standard" sx={{ width: '80%', marginLeft: '10px' }}
                            InputProps={{
                                style: { fontSize: '14px' }
                            }} /> */}
                        <Typography variant="body2" sx={{ marginLeft: '10px' }}>{orderDetails?.passcode}</Typography>
                    </div>


                    <div style={{ textAlign: 'left', marginTop: '30px' }}>
                        <Typography variant="h6" fontWeight={'Bold'}>Price Details</Typography>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                        <Typography variant="body2" sx={{ fontWeight: 600 }}>Number of Rooms :  </Typography>
                        {/* <TextField id="standard-basic"
                            value={orderDetails?.quantity} variant="standard" sx={{ width: '80%', marginLeft: '10px' }}
                            InputProps={{
                                style: { fontSize: '14px' }
                            }} /> */}
                        <Typography variant="body2" sx={{ marginLeft: '10px' }}>{orderDetails?.quantity}</Typography>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                        <Typography variant="body2" sx={{ fontWeight: 600 }}>Total Number of Days :  </Typography>
                        {/* <TextField id="standard-basic" value={orderDetails?.totalDays}
                            variant="standard" sx={{ width: '70%', marginLeft: '10px' }}
                            InputProps={{
                                style: { fontSize: '14px' }
                            }} /> */}
                        <Typography variant="body2" sx={{ marginLeft: '10px' }}>{orderDetails?.totalDays}</Typography>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                        <Typography variant="body2" sx={{ fontWeight: 600 }}>Total Price :  </Typography>
                        {/* <TextField value={orderDetails?.totalAmount}
                            id="standard-basic" variant="standard" sx={{ width: '70%', marginLeft: '10px' }}
                            InputProps={{
                                style: { fontSize: '14px' }
                            }} /> */}
                        <Typography variant="body2" sx={{ marginLeft: '10px' }}>{orderDetails?.totalAmount}</Typography>
                    </div>

                    <div style={{ textAlign: 'center', marginTop: '10px' }}>
                        <Button onClick={handleButtonClick} sx={{
                            backgroundColor: '#4caf50',
                            color: '#fff',
                            '&:hover': {
                                backgroundColor: '#388e3c',
                            },
                        }}>
                            Confirm Booking
                        </Button>
                    </div>
                </Grid>

                {/* <Grid container sx={{ alignItems: 'center', justifyContent: 'center' }}> */}
                <Grid item sx={{ textAlign: 'center', paddingTop: 0 }} md={12}>

                </Grid>
                {/* </Grid> */}

                {paymentComponent ?
                    <>
                        <Payment encryptedData={encryptedData}></Payment>
                    </>
                    :
                    ''
                }

                <Grid>
                    <Box>
                        <Modal
                            open={openModal}
                            onClose={handleCloseModal}
                            aria-labelledby="server-modal-title"
                            aria-describedby="server-modal-description"
                            sx={{
                                display: 'flex',
                                p: 1,
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}

                        >
                            <Box
                                sx={{
                                    position: 'relative',
                                    width: 400,
                                    bgcolor: '#fff',
                                    border: '2px solid #fff',
                                    borderRadius: 1,
                                    boxShadow: (theme) => theme.shadows[5],
                                    p: 4,
                                }}
                            >
                                <Typography id="server-modal-description" sx={{ pt: 2 }}>
                                    Thank you! Your Booking is confirmed.
                                </Typography>
                                <Typography id="server-modal-description" sx={{ pt: 2, fontWeight: 'bold' }}>
                                    Your Booking Id is : {orderDetails?.passcode}
                                </Typography>
                                <a href="/">
                                    <Button sx={{
                                        color: '#438f46',
                                        pt: 2,
                                        marginTop: 2
                                    }}>
                                        Okay
                                    </Button>
                                </a>
                            </Box>
                        </Modal>
                    </Box>
                </Grid>

            </Grid>

        </>
    );
}


