// eslint-disable-next-line no-undef
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { TextField, Grid, MenuItem, Select } from '@mui/material';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import { InputLabel } from '@mui/material';
import Textarea from '@mui/joy/Textarea';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

export default function CompleteBooking({ roomid, startDate, endDate, quantity }) {
    const [formData, setFormData] = useState(null);
    const navigate = useNavigate();

    const schema = yup.object().shape({
        name: yup.string().required('Name is required'),
        email: yup.string().email('Invalid email').required('Email is required'),
        phoneno: yup.string().required('Phone Number is required'),
        adults: yup.string().required('Number of Adults is required'),
        children: yup.string().required('Number of Children is required'),
        address: yup.string().required('Address is required'),
        pincode: yup.string().required('Pincode is required'),
        city: yup.string().required('City is required'),
        state: yup.string().required('State is required'),
    });

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = (data) => {
        schema.validate(data)
            .then(() => {
                console.log('Form data is valid:', data);
                handleClick();
            })
            .catch((validationErrors) => {
                console.log('Form data is not valid:', validationErrors.errors);
                // Handle validation errors as needed (e.g., display error messages to the user)
            });
    };

    const handleChange = (e) => {
        console.log("handle change");
        setFormData(prevData => {
            return {
                ...prevData,
                [e.target.name]: e.target.value
            };
        });
    };

    const handleClick = async () => {
        try {
            const requestData = {
                "roomid": Number(roomid),
                "startdate": startDate,
                "enddate": endDate,
                "quantity": Number(quantity),
                "customerName": formData.customerName,
                "email": formData.email,
                "phoneno": formData.phoneno,
                "adults": Number(formData.adults),
                "children": Number(formData.children),
                "address": formData.address,
                "pincode": formData.pincode,
                "state": formData.state,
                "city": formData.city
            };
            const response = await axios.post("https://phpstack-977481-4439950.cloudwaysapps.com/createBooking",
                requestData
            );
            if (response.data.status == true) {
                navigate(`/all-details/${response.data.orderheaderid}`);
            } else {
                // Handle error response
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    const getOptionsForRoom = (roomid) => {
        switch (roomid) {
            case '13':
                return { adults: ['1'], children: ['0'] };
            case '14':
                return { adults: ['1', '2'], children: ['0', '1', '2'] };          
            case '15':
                return { adults: ['1', '2', '3'], children: ['0', '1', '2'] };
            case '16':
                return { adults: ['1', '2', '3', '4'], children: ['0', '1', '2'] };
            default:
                return { adults: ['1', '2', '3', '4'], children: ['0', '1', '2'] };
        }
    };

    return (
        <>
            <Grid container className="mainGrid" spacing={5} sx={{ alignItems: 'center', justifyContent: 'center', minHeight: '100vh', }}>
                <Grid item xs={12} sm={12} md={12} className="roomcal">
                    <Box component="section" className='roomBox1' sx={{}}>
                        <Typography variant="h5" component="div" sx={{ textAlign: 'center', marginBottom: '30px' }} fontWeight="bold">
                            Complete the Details
                        </Typography>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography>Your Name</Typography>
                                    <TextField name="name" variant="outlined" {...register('customerName')} value={formData?.customerName} onChange={handleChange} fullWidth />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography>E-Mail</Typography>
                                    <TextField variant="outlined" name='email' {...register('email')} value={formData?.email} onChange={handleChange} type="email" fullWidth />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography>Phone Number</Typography>
                                    <TextField name='phoneno' {...register('phoneno')} value={formData?.phoneno} onChange={handleChange} variant="outlined" fullWidth />
                                </Grid>
                            </Grid>


                            <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                                {[
                                    { roomid: 'adults', label: 'Number of Adults' },
                                    { roomid: 'children', label: 'Number of Children' }
                                ].map((item) => (
                                    <Grid item xs={12} sm={12} md={12} key={item.roomid}>
                                        <Typography>{item.label}</Typography>
                                        <Select
                                            id={item.roomid}
                                            {...register(item.roomid)}
                                            value={formData ? formData[item.roomid] : ''}
                                            onChange={handleChange}
                                            displayEmpty
                                            name={item.roomid}
                                            variant="outlined"
                                            fullWidth
                                            labelId={`${item.roomid}-label`}
                                        >
                                            <MenuItem value="" disabled>
                                                {item.label}
                                            </MenuItem>
                                            {getOptionsForRoom(roomid)[item.roomid].map((option) => (
                                                <MenuItem key={option} value={option}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <p>{errors[item.roomid]?.message}</p>
                                    </Grid>
                                ))}
                            </Grid>

                            <Grid container spacing={4} sx={{ alignItems: 'center', marginTop: '10px' }}>
                                <Grid item xs={12} sm={12}>
                                    <Typography>Address</Typography>
                                    <Textarea
                                        name='address'
                                        minRows={5}
                                        cols={20}
                                        onChange={handleChange}
                                        style={{ width: '100%', resize: 'none', borderRadius: '5px', paddingTop: '10px' }}
                                        value={formData?.address}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} sx={{ marginTop: '30px' }}>
                                {/* <Grid item xs={12} sm={12} md={12}>
                                    <Typography>Pincode</Typography>
                                    <TextField name='pincode' {...register('pincode')} value={formData?.pincode} onChange={handleChange} variant="outlined" fullWidth />
                                </Grid> */}
                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography>City</Typography>
                                    <TextField variant="outlined" name='city' {...register('city')} onChange={handleChange} value={formData?.city} fullWidth />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography>State</Typography>
                                    <TextField name='state' {...register('state')} variant="outlined" onChange={handleChange} value={formData?.state} fullWidth />
                                </Grid>
                            </Grid>

                            <Grid container justifyContent="center">
                                <Grid item sx={{ marginTop: '30px' }}>
                                    <Button
                                        onClick={handleClick} type='submit'
                                        variant="contained" className='bookbtn'>
                                        Book a Room
                                    </Button>
                                </Grid>
                            </Grid>
                            <Typography variant="p" sx={{ color: 'red', fontSize: '13px' }}>*Extra persons and bed charges are applicable</Typography>
                        </form>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}
